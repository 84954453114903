@import 'src/styles/_variables';

.links {
  &__item {
    margin-bottom: 4px;
    padding: 13px 0;
    border-radius: @border-radius;

    & .MuiListItemIcon-root {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-right: 14px;

      svg {
        height: 23px;
        max-width: 27px;
        max-height: 25px;
        color: @black;
      }
    }

    li {
      padding: 0 29px;
    }

    &.selected {
      background-color: rgba(@purple, .1);
      color: @purple;

      .MuiTypography-root {
        font-weight: 700;
      }

      svg path {
        fill: @purple;
      }
    }

    & .MuiListItemButton-root {
      display: flex;
      padding: 0;

      & .MuiTypography-root {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }

      &:hover {
        background: none;
      }
    }

    &:hover {
      background: none;
      color: @purple;
      background-color: rgba(@purple, .1);

      svg path {
        fill: @purple;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .links {
    &__item {
      & .MuiListItemIcon-root {
        margin-right: 15px;
      }

      & .MuiCollapse-root {
        & .MuiListItemIcon-root {
          margin-right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .links {
    .MuiCollapse-wrapperInner {
      margin: 15px 0;
    }

    &__item {
      margin: 0 25px 4px 25px;

      li {
        padding: 0 25px;
      }

      & .MuiCollapse-root {
        & .links__item {
          margin: 0;
          padding: 13px 20px 13px 40px;
        }
      }
    }
  }
}