@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Ultra Thin'), url('/src/fonts/NHaasGroteskDSPro-15UltTh.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Ultra Thin Italic'), url('/src/fonts/NHaasGroteskDSPro-16UltThIt.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Thin'), url('/src/fonts/NHaasGroteskDSPro-25Th.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Thin Italic'), url('/src/fonts/NHaasGroteskDSPro-26ThIt.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Extra Light'), url('/src/fonts/NHaasGroteskDSPro-35XLt.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Extra Light Italic'), url('/src/fonts/NHaasGroteskDSPro-36XLtIt.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Light'), url('/src/fonts/NHaasGroteskDSPro-45Lt.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Light Italic'), url('/src/fonts/NHaasGroteskDSPro-46LtIt.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Regular'), url('/src/fonts/NHaasGroteskDSPro-55Rg.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Italic'), url('/src/fonts/NHaasGroteskDSPro-56It.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Medium'), url('/src/fonts/NHaasGroteskDSPro-65Md.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Medium Italic'), url('/src/fonts/NHaasGroteskDSPro-66MdIt.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Bold'), url('/src/fonts/NHaasGroteskDSPro-75Bd.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Bold Italic'), url('/src/fonts/NHaasGroteskDSPro-76BdIt.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Black'), url('/src/fonts/NHaasGroteskDSPro-95Blk.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: local('Neue Haas Grotesk Black Italic'), url('/src/fonts/NHaasGroteskDSPro-96BlkIt.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
