@import "src/styles/_variables";

.account-menu {
  position: relative;

  & .MuiButtonBase-root {
    padding: 0;
    color: #000;

    &:hover {
      background: none;
    }
  }

  &__logo {
    font-size: 15px;

    & .MuiAvatar-root {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }

    svg {
      margin-left: 8px;
    }

    .name-wrapper {
      display: flex;
      flex-direction: column;
    }

    .role {
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      color: @purple;
      width: fit-content;
      padding: 4px 8px;
      background-color: rgba(@purple, .1);
      border-radius: @border-radius;
      margin-top: 4px;
    }
  }

  &__menu {
    &-header {
      position: relative;

      display: flex;
      align-items: center;
      padding: 24px 24px 19px;
      text-decoration: none;
      border-bottom: 2px solid @light-grey;

      .close-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        color: #000;
        height: 18px;

        &:hover {
          cursor: pointer;
        }
      }

      &-wrapper {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
        background-color: @light-grey;
        border-radius: @border-radius;
      }

      &-profile {
        text-decoration: none;
        padding: 20px 24px;
        margin-top: 8px;
        background-color: @white;
        border-radius: @border-radius;
        color: @dark-grey;
        cursor: pointer;
        letter-spacing: .5px;

        &:hover {
          box-shadow: @box-shadow;
          color: @black;
        }
      }

      & .MuiAvatar-root {
        width: 76px;
        height: 76px;
        margin-right: 12px;
      }

      &-info {
        box-sizing: border-box;
        width: 100%;
        min-width: 0;
        padding: 0 24px;

        .name {
          font-weight: 700;
          color: #000;
        }

        .email {
          overflow: hidden;
          color: @purple;
          text-overflow: ellipsis;
          letter-spacing: 1px;
        }
      }
    }

    &-workspaces {
      padding: 19px 24px;
      border-bottom: 2px solid @light-grey;

      &-wrapper {
        margin-top: 10px;
        margin-bottom: 30px;
        max-height: 300px;
        overflow-y: auto;
        
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;

          &-track {
            background-color: @white;
            border-radius: @border-radius;
          }

          &-thumb {
            border-radius: @border-radius;
            background: @purple;

          }
        }
      }

      &-manage {
        text-decoration: none;
        color: @dark-grey;

        &:hover {
          color: @purple;
        }
      }
    }

    .MuiMenu-paper {
      min-width: 340px;
      max-width: 450px;
      border-radius: @border-radius;

      .MuiMenu-list {
        padding: 0;
      }
    }

    &-links {
      padding: 24px 37px;

      &-item {
        &.MuiButtonBase-root {
          padding: 0;

          &:hover {
            background: none;
          }

          &:not(:last-child) {
            padding-bottom: 24px;
          }
        }

        .link {
          margin-left: 25px;
        }

        &:hover p {
          font-weight: 700;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .account-menu {
    margin-left: 0;
  }
}