@import 'src/styles/_variables';

.support-label {
  font-size: 16px;
  color: @dark-grey;

  a {
    color: @dark-grey;

    &:hover {
      color: @purple;
    }
  }
}