@import "src/styles/_variables";

.confirm-modal {
  &__icon {
    display: inline-block;
    margin-right: @margin-s;
    align-self: start;
    margin-top: 8px;
  }

  & .MuiPaper-root {
    padding: 30px 40px;
    border-radius: @border-radius;
    position: relative;
    max-height: 70vh;
    min-width: 350px;
  }

  & .MuiDialogContent-root {
    padding: 1px;  
  }

  & .MuiBackdrop-root {
    background-color: rgba(@black, .85);
  }

  & h2.MuiTypography-root {
    padding: 0;
    font-weight: 500;
    font-size: 24px;
  }

  & .MuiDialogActions-root {
    padding: 0;
    margin-top: 20px;

    & .MuiButtonBase-root {
      border-radius: @border-radius;
      padding: 9.5px 20px;
      color: @white;
      font-weight: 700;

      &.submit-btn:not(.Mui-disabled) {
        background-color: @purple;

        &:hover {
          background-color: @dark-purple;
        }
      }

      &.close-btn {
        background-color: transparent;
        color: rgba(@purple, .7);
        outline: 1px solid rgba(@purple, .7);

        &:hover {
          color: @dark-purple;
          background-color: rgba(@purple, .1);
        }
      }
    }

    .footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .error {
      font-size: 14px;
      color: @red;
    }
  }

  & *::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    &-track {
      background-color: @white;
      border-radius: @border-radius;
    }

    &-thumb {
      border-radius: @border-radius;
    }
  }
}

::-webkit-scrollbar {
  &-thumb {
    background: @purple;
  }
}

@media screen and (max-width: 850px) {
  .confirm-modal {
    & .MuiPaper-root {
      padding: 30px;
    }
  }
}

@media screen and (max-width: 450px) {
  .confirm-modal {
    & .MuiPaper-root {
      padding: 20px;
      min-width: 250px;
    }
    h2.MuiTypography-root {
      font-size: 18px;
    }
  }
}
