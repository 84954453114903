@import '/src/styles/fonts';
@import "src/styles/_variables";

body,
html {
  margin: 0;
  font-family: 'Neue Haas Grotesk';
  font-size: 17px;
}

.MuiTooltip-popper {
  z-index: 11990 !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: @dark-grey;
}