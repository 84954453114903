@import "src/styles/_variables";

.error-page {
  padding: 0;
  display: flex;
  justify-content: center;
  min-height: 100%;
  text-align: center;

  &__card {
    max-height: 550px;
    max-width: 860px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .link {
      color: @purple;
      cursor: pointer;
    }
  }
}
