@import "src/styles/_variables";

.app {
  .header {
    height: inherit;

    h1 {
      color: @purple;
    }
  }
}

.header {
  height: auto;

  .mobile-header {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 14px 65px 14px 29px;

    border-bottom: 2px solid#F2F2F2;

    &-buttons {
      display: flex;
      align-items: center;
      margin-left: auto;

      &-item.MuiIconButton-root {
        padding: 13px;
        margin-right: 15px;
        color: @black;
        background-color: rgba(@purple, .1);

        &:hover {
          background-color: rgba(@purple, .2);
        }
      }
    }
  }

  &__main {
    display: flex;
    padding: 30px 65px;

    h1 {
      font-size: 50px;
      font-weight: 700;
      line-height: 55px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .MuiIconButton-root:hover {
      background: none;
    }
  }

  .info {
    color: @light-blue;
  }
}

@media screen and (max-width: 850px) {
  .header {
    .mobile-header {
      padding: 25px;
      display: initial;

      &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;

        svg {
          width: 160px;
        }
      }

      &_menu {
        background-color: @light-grey;
        padding: 20px 0;
      }

      &_menu.close {
        display: none;
      }

      &_menu.open {
        display: block;
      }

      .header__header-buttons {
        flex-direction: column;
        align-items: start;
        font-size: 16px;

        .MuiButtonBase-root {
          margin-right: 12px;

          &.account-menu__logo {
            font-size: 16px;
          }
        }
      }

      .account-mobile-menu {
        background-color: @white;
        border-radius: @border-radius;
        padding: 15px;
        margin: 0 25px;
        display: block;

        & > .MuiBox-root {
          margin-bottom: 10px;
        }
      }

      &_footer {
        margin: 20px 25px 0 25px;
      }
    }

    &__header {
      padding: 0 20px;
      border: none;
      justify-content: left;

      &-buttons {
        display: none;
      }
    }

    &__main {
      padding: 25px;

      h1 {
        font-size: 30px;
        line-height: 33px;
      }
    }

    .info {
      font-size: 17px;
    }
  }
}