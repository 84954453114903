@import 'src/styles/_variables';

.app {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: 100vh;
  max-width: 100vw;

  &__main {
    display: flex;
    flex-direction: column;
    min-width: 350px;  

    & .content {
      flex-grow: 1;
      background-color: @white;
    }

    &:not(.full-page) {
      width: calc(100% - @navigation-pane-width);
    }

    &:not(.separate-page) .content {
      background-color: @main-background;
      padding: 37px 60px;
    }

    &.full-page {
      width: 100vw;
    }
  }

  .mt-s {
    margin-top: @margin-s;
  }

  .mt-m {
    margin-top: @margin-m;
  }

  &__logo {
    cursor: pointer;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

@media screen and (max-width: 850px) {
  .app {
    margin-top: -1px;

    &__main {
      &.full-page, &:not(.full-page) {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .app {
    margin-top: -1px;

    &__main {
      &:not(.separate-page) .content {
        padding: 20px 30px;
      }
    }
  }
}