@import "src/styles/_variables";

.workspaces-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & > p {
      font-weight: 700;
      font-size: 30px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 89%;
  }

  & .buttons {
    display: flex;
    margin-left: auto;
    &__search {
      margin-right: 20px;

      & .MuiInputBase-root {
        box-sizing: border-box;
        width: 230px;
        max-width: 230px;
        margin: 0;
        padding-left: 20px;
  
        border-radius: @border-radius;
  
        & fieldset,
        &:hover fieldset {
          border-color: @black;
        }

        &.Mui-focused fieldset {
          border-color: @purple;
        }
      }
    }

    &__create {
      border-radius: @border-radius;
      background-color: @purple;

      &:hover {
        background-color: @dark-purple;
        outline-color: @dark-purple;
      }
    }
  }
}

.workspaces-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .workspaces-pagination {
    display: flex;
    justify-content: end;
    margin: auto 60px 0;
  }
}

.workspace-item {
  background-color: @white;
  border-radius: @border-radius;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  max-height: 90px;

  &:not(.menu) {
    max-width: 45%;

    & .MuiAvatar-root {
      width: 50px;
      height: 50px;
      border-radius: @border-radius;
      margin-right: 20px;
    }

    & .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    & .name {
      color: @purple;
      font-size: 24px;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .role {
      font-size: 16px;
      color: @white;
      border-radius: @border-radius;
      padding: 8px 24px;
      font-weight: 700;

      &.admin {
        background-color: rgba(@red, .2);
        color: @red;
      }

      &.member, &.super-user {
        background-color: rgba(@cyan, .2);
        color: #5d9cec;
      }
    }

    &:hover {
      background-color: rgba(@purple, .1);
      box-shadow: @box-shadow;

      .role {
        &.member, &.admin, &.super-user {
          background-color: @white;
          color: @purple;
        }
      }
    }
  }

  &.menu {
    border-radius: @border-radius;
    padding: 15px 16px;
    background-color: @light-grey;
    margin-bottom: 15px;

    & .MuiAvatar-root {
      width: 40px;
      height: 40px;
      border-radius: @border-radius;
      margin-right: 20px;
    }

    & .main {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      flex-grow: 1;
      text-overflow: ellipsis;
    }

    & .name {
      color: @black;
      font-size: 15px;
      font-weight: 500;
      text-overflow: ellipsis;
    }

    & .role {
      font-size: 14px;
      color: @purple;
      padding-top: 5px;
    }

    &:hover {
      background-color: rgba(@cyan, .5);
      box-shadow: @box-shadow;
    }
  }
}

@media screen and (max-width: 1300px) {
  .workspaces-page {
    &__main {
      flex-wrap: nowrap;
    }
  }

  .workspace-item:not(.menu) {
    max-width: 70%;
  }
}


@media screen and (max-width: 1200px) {
  .workspace-item:not(.menu) {
    max-width: 100%;
  }

  .workspaces-page {
    &__header {
      flex-direction: column;
      align-items: start;
    }
  }
}

@media screen and (max-width: 1100px) {
  .workspaces-page {
    .buttons {
      flex-direction: column;
      align-items: start;
      width: 100%;

      & button {
        width: 100%;
      }

      &__search {
        width: 100%;
        margin-bottom: 15px;

        & .MuiFormControl-root {
          width: 100%;
        }
  
        & .MuiInputBase-root {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .workspace-item {
    &:not(.menu) .name {
      font-size: 20px;
    }
  }
}


@media screen and (max-width: 450px) {
  .workspace-item {
    &:not(.menu) {
      & .main {
        flex-direction: column;
        align-items: start;
      }

      & .name {
        max-width: 100%;
      }

      & .role {
        color: @white;
        padding:0;
        font-weight: 400;
        margin-top: 8px;

        &.admin, &.member, &.super-user {
          background-color: transparent;
        }
      } 

      &:hover {
        .role {
          &.member, &.admin, &.super-user {
            background-color: transparent;
          }
        }
      }
    }
  }
}


