@import "src/styles/_variables";

.hamburger {
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  .line {
    width: 30px;
    height: 2px;
    background-color: @dark-grey;
    display: block;
    margin: 7px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &.is-active {
    .line{
      background-color: @purple;
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(1) {
      -webkit-transform: translateY(9px) rotate(45deg);
      -ms-transform: translateY(9px) rotate(45deg);
      -o-transform: translateY(9px) rotate(45deg);
      transform: translateY(9px) rotate(45deg);
    }

    .line:nth-child(3) {
      -webkit-transform: translateY(-9px) rotate(-45deg);
      -ms-transform: translateY(-9px) rotate(-45deg);
      -o-transform: translateY(-9px) rotate(-45deg);
      transform: translateY(-9px) rotate(-45deg);
    }
  }
}